<template>
    <section class="content">
        <div class="overlay-wrapper">
            <form role="form" @submit.prevent="submitForm">
            <div class="card">
                <div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4 form-group">
                    <label class="control-label">TANGGAL</label>
                    <datepicker v-model="form.tanggal" :options="{ orientation: 'bottom', format: 'dd/mm/yyyy', autoclose: true }" id="tanggal" />
                    </div>
                    <div class="col-md-4 form-group">
                        <label class="control-label">REKENING</label>
                        <v-select :options="totalBank" v-model="form.rekening_id" :reduce="opt => opt.rekening_id" />
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12">
                    <label class="control-label">UPLOAD DATA INVOICE</label>
                    <button class="btn btn-secondary mb-3 float-right" @click.prevent="downloadTemplate">
                        <i class="fa fa-download"></i> Unduh Template
                        <span v-if="loadingDownload" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                    </div>
                    <div class="col-md-12">
                        <form action="/target" ref="dzone" class="dropzone card" style="border-style: dashed;">
                        <div class="overlay" v-if="loading">
                        <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        </div>
                        <div class="dz-message text-muted" data-dz-message><h4><div><i class="fa fa-upload"></i></div><span>Upload file Anda disini</span></h4></div>
                        </form>
                        <span class="text-muted" v-if="!msg ? false : true">{{msg}}</span>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" :disabled="(!form.rekening_id || loading2)" class="btn btn-primary" id="submit">
                    Simpan
                <span v-if="loading2" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>
            </div>
            </form>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import Dropzone from "dropzone";
import XLSX from "xlsx";
import Swal from 'sweetalert2';
import $ from 'jquery';
import "dropzone/dist/dropzone.css";
import datepicker from "@/components/Datepicker";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: 'FormRekonWinpay',
    data() {
        return {
            errors: [],
            roles: '',
            msg: '',
            loading: false,
            loading2: false,
            loadingDownload: false,
            data: [],
            formTitle: 'Tambah',
            form: {
                tanggal: moment().format("DD/MM/YYYY"),
                rekening_id: '',
            },
            formData: [],
            totalCourier: [],
            totalBank: [],
            disabled: true,
            file: {},
            loadingContent: '',
        }
    },
    components: {
        datepicker,
        vSelect,
    },
    computed: {},
    created: function() {
        this.loadBank();
        this.form.tanggal = moment().format("DD/MM/YYYY");
        $('.loading-overlay').removeClass('show');
    },
    methods: {
        loadBank: function () {
        authFetch("/payment/saldo/bank")
            .then((res) => {
                if (res.status === 201) {} else if (res.status === 400) {}
                return res.json();
            })
            .then((js) => {
                this.totalBank = js.data;
            });
        },
        btnFile() {
            var input = document.querySelector("#excelFile");
            input.click();
        },
        downloadTemplate: function()
        {
            this.loadingDownload = true;
            authFetch("/file/aset/template_winpay", {
                method: 'GET',
            })
            .then(response => response.blob())
            .then(blob => {
                this.loadingDownload = false;
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "template_rekon_winpay.csv";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();  //afterwards we remove the element again         
            });

        },
        submitForm: function (ev) {
        var self = this;
        const e = this.$refs;

        var allData = { group: self.form, items: self.formData };
        var urlSubmit = "/report/saldo/rekons_winpay";

        var formData = new FormData();
            formData.append('userfile', this.file);
            formData.append('rekening_id', this.form.rekening_id);
            formData.append('tanggal', this.form.tanggal);

        this.loading2 = true;
        Swal.fire({
        title: "Simpan?",
        showCancelButton: true,
        confirmButtonText: `Ya`,
        cancelButtonText: "Tidak",
        }).then((result) => {
        this.loadingContent = true;
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
        authFetch(urlSubmit, {
            method: "POST",
            body: formData,
            headers: {
            'Content-Type': null,
            },
        })
        .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            } else if (res.status === 500){
                Swal.fire("Timeout!", ``, "error");
                this.loadingContent = false;
                this.loading2 = false;
                this.msg = '';
            }
            return res.json();
        })
        .then((js) => {
            this.loadingContent = false;
            this.loading2 = false;
            let finalMsg = '';
            if (js.success) {
                if(js.data.length > 0) {
                    let failed = js.data;
                    let detailData =
                    '<div style="overflow-y: auto;max-height:150px;" class="border rounded p-2 mt-2 text-muted text-left">';

                    for (let i in failed) {
                        detailData +=
                            "<b>Transaction ID " +
                            failed[i].trans_id +
                            "</b><br><i>" +
                            failed[i].message +
                            "</i><br>";
                    }

                    detailData += "</div>";

                    finalMsg =
                        '<i class="fa fa-exclamation-triangle"></i> Ada invoice yang gagal rekonsiliasi.' +
                        detailData;

                    Swal.fire("Proses Berhasil", finalMsg, "success");
                } else {
                    Swal.fire("Proses Berhasil", `Invoice telah berhasil rekonsiliasi.`, "success");
                }

                this.form = {};
                this.msg = '';
            } else {
                Swal.fire("Proses Gagal", `Data invoice winpay kemungkinan sudah pernah diupload / tidak valid.`, "error");
            }
        });
        }
        });

        ev.preventDefault();
        },
    },
    mounted() {
        const e = this.$refs;

        //upload excel
        Dropzone.autoDiscover = false;
        let myDropzone = new Dropzone(this.$refs.dzone, {
            uploadMultiple: false, maxFiles: 1,
            createImageThumbnails: false,autoProcessQueue: false,
            url: 'getMeSomeUrl',
            accept: function(file, done) {
                console.log('accept', file, done)
                this.removeFile(file);
            },
            success: function(file)
            {
                this.removeFile(file);
            }
        });
        myDropzone.on("addedfile", file => {
            this.loading = true;
            this.file = file;
            setTimeout(() => {
                this.loading = false;
                this.msg = file.name;
            }, 2000);
        });
        myDropzone.on("maxfilesexceeded", function (file) {
            myDropzone.removeAllFiles();
        });
    }
}
</script>
<style scoped>
    .drag-area {
    border: 2px dashed rgb(114, 114, 114);
    min-height: 250px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    }

    .drag-area.active {
    border: 2px solid rgb(133, 133, 133);
    }

    .drag-area .icon {
    font-size: 100px;
    color: rgb(139, 139, 139);
    }

    .drag-area header {
    font-size: 30px;
    font-weight: 500;
    color: rgb(126, 126, 126);
    }

    .drag-area span {
    font-size: 25px;
    font-weight: 500;
    color: rgb(129, 129, 129);
    margin: 10px 0 10px 0;
    }

    .drag-area button {
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 500;
    border: none;
    outline: none;
    background: rgb(206, 206, 206);
    color: #5256ad;
    border-radius: 5px;
    cursor: pointer;
    }

    .drag-area img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    }
</style>